export const useSocialBusinessStore = defineStore("SocialBusinessStore", () => {
  const map = ref<{ latitude: string; longitude: string; mapZoom: number }>({
    latitude: "50.969467",
    longitude: "10.279607",
    mapZoom: 6,
  });

  const organisationTypeFilter = ref<string | null>(null);
  const industryTypeFilter = ref<string | null>(null);
  const companyNameSearchString = ref<string | null>(null);
  const selectedCompany = ref<string | null>(null);

  return {
    map,
    organisationTypeFilter,
    industryTypeFilter,
    companyNameSearchString,
    selectedCompany,
  };
});
