import articlePreviewAuthorTopic from "./magazine/articlePreviewAuthorTopic";
import markDefs from "./markDefs";
import groqWithPortableLink from "~/groq/withPortableLink";
import groqCallToAction from "~/groq/callToAction";
import groqFile from "~/groq/file";
import groqImage, { groqImageInlineAsset } from "~/groq/image";
import groqBestSmallImage from "~/groq/bestSmallImage";
import groqTwoThirdsStack from "~/groq/twoThirdsStack";
import groqBlockContent from "~/groq/blockContent";

export default /* groq */ `'pageComponents': coalesce(
  pageComponents[isHidden != true]{
    ...,
    ${groqWithPortableLink(["info", "catchyHeading", "text", "body"])},
    ${groqCallToAction},
    ${groqImage("image")},
    ${groqImage("heroImage")},
    ${groqImage("smallImage")},
    cards[] {
      ...,
      ${groqCallToAction},
      ${groqImage("image")}
    },
    ${groqTwoThirdsStack},
    teamMember[] -> {
      'image': image {
        ...,
        ${groqImageInlineAsset},
        'altText': 'Portrait von ' + ^.firstName,
        'isDark': coalesce(isDark, false)
      },
      firstName,
      motivation,
      description
    },
    _type == 'faqList' => @{
      ...,
      'faqArticles': coalesce(faqArticles[] -> {..., ${groqBlockContent(["body"])}}, []),
    },
    _type == 'tariffCalculator' => @{
      ...,
      "calculator": coalesce(calculator->{
        'businessCustomerPreSelected': coalesce(businessCustomerPreSelected, false),
        'canSwitchBusinessCustomer': coalesce(canSwitchBusinessCustomer, false),
        partnerTariffId,
        tabs[] {
          title,
          icon,
          tariffs[] -> {
            tariffSubType,
            runTimeType,
            uniqueSellingPoints,
            tariffType,
            tariffSwitch {
              ...,
              tariff->
            },
          },
        },
      }, {})
    },
    _type == "singleAnimation" => @{
      ...,
      ${groqFile("image")}
    },
    _type == "partnerMap" => @{
      ...,
      ${groqFile("dataset")}
    },
    _type == "brandsRow" => @{
      ...,
      "brands": brands[] { ..., ${groqImageInlineAsset}}
    },
    _type == 'cardsRow' => @{
      ...,
      cards[] {
        ...,
        ${groqCallToAction},
        image {
          ...,
          ${groqImageInlineAsset},
        },
        info[] {
          ...,
          ${markDefs}
        },
      },
    },
    _type == 'magazineRow' => @{
      ...,
      "topicTitle": coalesce(topic->title, ""),
      "fallbackArticles": *[_type == "magazineArticle" ] | order(lastModifiedAt desc)[0..2] {
        ${articlePreviewAuthorTopic},
        ${groqBestSmallImage},
      },
      "topicArticles": *[_type == "magazineArticle" && coalesce(references(^.topic._ref), true) ] | order(lastModifiedAt desc)[0..2] {
        ${articlePreviewAuthorTopic},
        ${groqBestSmallImage},
      },
    },
    _type == "youtubeVideo" => @{
      ...,
      ${groqImage("posterImage")}
    },

  },

[])`;
