<template>
  <TemplateForm
    :form-id="formId"
    :form-title="'Bestellformular zum Kommunikationspaket.'"
    :order-packets="orderPackets"
    @submit="onSubmit"
  />
</template>

<script setup lang="ts">
import TemplateForm from "./TemplateForm.vue";
import type { orderPacket } from "~/types/shared/order-form";
import type { CommunicationPackageOrder } from "~/src/generated-sources/public";

defineProps<{ formId: string }>();
const { $brain } = useNuxtApp();

const onSubmit = (fields: any) => {
  const content: CommunicationPackageOrder = {
    emailAddress: fields.emailAddress,
    ...(fields.customerId && { customerId: fields.customerId }),
    ...(fields.address && { address: fields.address }),
    products: fields.products,
    ...(fields.message && { message: fields.message }),
    type: "businessCustomer",
  };
  return $brain.public.orders.postCommunicationPackageOrderRequest(content);
};

const orderPackets: orderPacket[] = [
  {
    singularTitle: "Plakette aus Acrylglas",
    pluralTitle: "Plaketten aus Acrylglas",
    id: "acrylglas",
    description:
      "Selbstklebende Plakette aus Acrylglas, Weiß bedruckt mit schwarzer Schrift. Maße 21 x 10,5 cm.",
    items: [
      {
        title: "Plakette Ökostrom",
        id: "oekostrom",
        steps: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      },
      {
        title: "Plakette Ökogas",
        id: "oekogas",
        steps: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      },
    ],
  },
  {
    singularTitle: "Auszeichnung",
    pluralTitle: "Auszeichnungen",
    id: "auszeichnungen",
    image: "auszeichnung.jpg",
    description:
      "Energiewende-Zertifikat in DIN A4 zum Aufhängen. Es zeigt, wie dein Unternehmen mit Polarstern die Energiewende unterstützt: Von der CO2 - Ersparnis, dem weltweiten Ausbau erneuerbarer Energien von Deutschland bis nach Kambodscha und Madagaskar und die Vermeidung radioaktiven Abfalls. Versand ausgedruckt auf weißem Papier (Offset Premium White) oder als PDF.",
    items: [
      {
        title: "Auszeichnung Ökostrom",
        id: "oekostrom",
        steps: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      },
      {
        title: "Auszeichnung Ökogas",
        id: "oekogas",
        steps: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      },
    ],
  },
  {
    singularTitle: "Tür- und Fensteraufkleber",
    id: "aufkleber",
    description:
      "Selbstklebende Folienschrift. Durchsichtig mit schwarzer Schrift. Maße 34 x 19,6 cm.",
    items: [
      {
        title: "Aufkleber Ökostrom",
        id: "oekostrom",
        steps: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      },
      {
        title: "Aufkleber Ökogas",
        id: "oekogas",
        steps: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      },
    ],
  },
  {
    singularTitle: "Flyer",
    id: "flyer",
    description:
      "Infos rund um Polarstern, unsere Produktpalette und unser Engagement für die weltweite Energiewende. Inklusive ein Wechselcode. Zum einfachen Weitergeben und Weiterempfehlen. Maße 41 x 15 cm. Mindestbestellmenge: 50 Stück.",
    items: [
      {
        title:
          "Flyer mit Infos rund um Polarstern, Wirklich Ökostrom und Wirklich Ökogas.",
        id: "flyerCount",
        steps: ["0", "50", "100", "200", "500", "1000", ">1000"],
      },
    ],
  },
  {
    singularTitle: "Postkarte",
    pluralTitle: "Postkarten",
    id: "postkarte",
    description:
      "Kurz und knackig zum Mitnehmen: Ökostrom-Postkarte von Polarstern. Inklusive individueller Wechselcode. Zum Mitnehmen und Weitergeben.Maße 10,5 x 14,8 cm. Zwei Motive zur Wahl: Team-Bild oder Wasserkaft-Bild. Mindestbestellmenge je Motiv 20 Stück.",
    items: [
      {
        title: "Motiv Polarstern-Team",
        id: "polarsternTeam",
        steps: ["0", "20", "40", "60", "80", "100", "200", "500", "1000", ">1000"],
      },
      {
        title: "Motiv Wasserkraft",
        id: "wasserkraft",
        steps: ["0", "20", "40", "60", "80", "100", "200", "500", "1000", ">1000"],
      },
    ],
  },
  {
    singularTitle: "Banner",
    id: "banner",
    description:
      "Banner in verschiedenen Designs und Texten verfügbar. Verlinkung auf die Polarstern-Webseite. Bei Bedarf erstellen wir einen individuellen Text für dein Unternehmen.",
  },
  {
    singularTitle: "Wanderausstellung",
    pluralTitle: "Wanderausstellungen",
    id: "wanderausstellung",
    description:
      "10 Fotomotive aus Kambodscha in verschiedenen Größen. Zur Ausstellung in den Geschäftsräumen. Zusätzlich Aufsteller mit Informationen zu den Energiewende-Initiativen sowie Postkarten mit diversen Motiven zum Mitnehmen.",
  },
];
</script>
