<template>
  <div class="additional-information">
    <FormKit
      type="radio"
      name="Format"
      label="Format"
      :options="[
        { value: 'PDF', label: 'PDF zum Selbstausdrucken' },
        { value: 'Druck', label: 'Druck' },
      ]"
    />
  </div>
</template>
