<template>
  <div class="page-components e2e-page-components">
    <component
      :is="loadComponent(componentProps.cmsType || 'unknown')"
      v-for="componentProps in pageComponents"
      :key="componentProps.cmsKey"
      v-bind="addContainer(componentProps)"
      :class="'psc-' + componentProps.cmsType"
    />
  </div>
</template>

<script setup lang="ts">
import UnknownPageComponent from "../04_sectionsFromCMS/UnknownPageComponent.vue";

import BlockGeneric from "../04_sections/BlockGeneric.vue";
import BlockCalculator from "../04_sections/BlockCalculator.vue";
import BlockContactForm from "../04_sections/BlockContactForm.vue";
import BlockNewsletterForm from "../04_sections/BlockNewsletterForm.vue";
import BlockOrderForm from "../04_sections/BlockOrderForm.vue";

// Dynamically filled components from the CMS
import BrandsRow from "../04_sectionsFromCMS/BrandsRow.vue";
import CardsRow from "../04_sectionsFromCMS/CardsRow.vue";
import CatchyHeader from "../04_sectionsFromCMS/CatchyHeader.vue";
import CheckBoxTeaser from "../04_sectionsFromCMS/CheckBoxTeaser.vue";
import EkomiSection from "../04_sectionsFromCMS/EkomiSection.vue";
import FaqList from "../04_sectionsFromCMS/FaqList.vue";
import IFrame from "../04_sectionsFromCMS/IFrame.vue";
import ImpactKpiCounter from "../04_sectionsFromCMS/ImpactKpiCounter.vue";
import InfoHeader from "../04_sectionsFromCMS/InfoHeader.vue";
import InfoSection from "../04_sectionsFromCMS/InfoSection.vue";
import MagazineRow from "../04_sectionsFromCMS/MagazineRow.vue";
import RelieveCalculator from "../04_sectionsFromCMS/RelieveCalculator.vue";
import SealRow from "../04_sectionsFromCMS/SealRow.vue";
import SingleAnimation from "../04_sectionsFromCMS/SingleAnimation.vue";
import SingleImage from "../04_sectionsFromCMS/SingleImage.vue";
import SingleTable from "../04_sectionsFromCMS/SingleTable.vue";
import SingleText from "../04_sectionsFromCMS/SingleText.vue";
import SingleTitle from "../04_sectionsFromCMS/SingleTitle.vue";
import TariffCalculator from "../04_sectionsFromCMS/TariffCalculator.vue";
import TeamCards from "../04_sectionsFromCMS/TeamCards.vue";
import TwoThirdsStack from "../04_sectionsFromCMS/TwoThirdsStack.vue";
import YoutubeVideo from "../04_sectionsFromCMS/YoutubeVideo.vue";

import FormCancellation from "../04_sectionsFromCMS/FormCancellation.vue";
import FormContact from "../04_sectionsFromCMS/FormContact.vue";
import FormNewsletter from "../04_sectionsFromCMS/FormNewsletter.vue";
import FormOrder from "../04_sectionsFromCMS/FormOrder.vue";
import FormRecommendation from "../04_sectionsFromCMS/FormRecommendation.vue";

// Components with static content and only referenced in the CMS
import FurtherQuestions from "../04_sections/FurtherQuestions.vue";
import ImpactCalculator from "../04_sections/ImpactCalculator.vue";
import MieterstromSignup from "../04_sections/MieterstromSignup.vue";
import PartnerMap from "../04_sections/PartnerMap.vue";
import RecommendUs from "../04_sections/RecommendUs.vue";
import SocialSharing from "../04_sections/SharingSection.vue";
import SocialMediaRow from "../04_sections/SocialMediaRow.vue";
import TenantPowerRegistrationEntry from "../04_sectionsFromCMS/TenantPowerRegistrationEntry.vue";

import type { PageComponent } from "~/types/cms/page-component";
import { getIsMaintenanceActive, IS_SOFT_MAINTENANCE_ACTIVE } from "~/config";

const isMaintenanceActive = getIsMaintenanceActive();
const isSoftMaintenanceActive = getIsMaintenanceActive(IS_SOFT_MAINTENANCE_ACTIVE);

// Please also add new component type in page-component.ts
const componentsMapping: { [key: string]: Component } = {
  brandsRow: BrandsRow,
  cardsRow: CardsRow,
  catchyHeader: CatchyHeader,
  checkBoxTeaser: CheckBoxTeaser,
  ekomiRow: EkomiSection,
  faqList: FaqList,
  furtherQuestions: FurtherQuestions,
  iframe: IFrame,
  impactCalculator: ImpactCalculator,
  infoHeader: InfoHeader,
  infoSection: InfoSection,
  kpiCounter: ImpactKpiCounter,
  magazineRow: MagazineRow,
  overlayCards: CardsRow,
  partnerMap: PartnerMap,
  recommendUs: RecommendUs,
  relieveCalculator: RelieveCalculator,
  sealRow: SealRow,
  singleAnimation: SingleAnimation,
  singleImage: SingleImage,
  singleTable: SingleTable,
  singleText: SingleText,
  singleTitle: SingleTitle,
  socialMediaRow: SocialMediaRow,
  socialSharing: SocialSharing,
  tariffCalculator: isMaintenanceActive ? BlockCalculator : TariffCalculator,
  teamCards: TeamCards,
  tenantPowerRegistration: isSoftMaintenanceActive ? BlockGeneric : MieterstromSignup,
  twoThirdsStack: TwoThirdsStack,
  youtubeVideo: YoutubeVideo,
  tenantPowerRegistrationEntry: TenantPowerRegistrationEntry,

  // Form components
  cancelationForm: isMaintenanceActive ? BlockGeneric : FormCancellation,
  formContact: isMaintenanceActive ? BlockContactForm : FormContact,
  formNewsletter: isMaintenanceActive ? BlockNewsletterForm : FormNewsletter,
  formOrder: isMaintenanceActive ? BlockOrderForm : FormOrder,
  recommendationForm: isMaintenanceActive ? BlockGeneric : FormRecommendation,

  // TODO: Fallback component, remove or make less visible before go-live
  unknown: UnknownPageComponent,
};

const loadComponent = (componentType: string) => {
  return componentsMapping[componentType] || UnknownPageComponent;
};

const noContainerComponents = ["iframe"];
const addContainer = (
  componentProps: PageComponent,
): PageComponent & { hasContainer?: boolean } => {
  return {
    ...componentProps,
    hasContainer: !noContainerComponents.includes(componentProps.cmsType as string),
  };
};

defineProps<{
  pageComponents: PageComponent[];
}>();
</script>
