<template>
  <TemplateForm
    :form-id="formId"
    :form-title="'Bestellformular zum Mieterstrom-Kommunikationspaket.'"
    :order-packets="orderPackets"
    @submit="onSubmit"
  />
</template>

<script setup lang="ts">
import TemplateForm from "./TemplateForm.vue";
import type { orderPacket } from "~/types/shared/order-form";
import type { CommunicationPackageOrder } from "~/src/generated-sources/public";
defineProps<{ formId: string }>();

const { $brain } = useNuxtApp();

/* TODO: create a generic function and use it here and in CommunicationBusiness.vue */
const onSubmit = (fields: any) => {
  const content: CommunicationPackageOrder = {
    emailAddress: fields.emailAddress,
    ...(fields.customerId && { customerId: fields.customerId }),
    ...(fields.address && { address: fields.address }),
    products: fields.products,
    ...(fields.message && { message: fields.message }),
    type: "tenantPower",
  };
  return $brain.public.orders.postCommunicationPackageOrderRequest(content);
};

const orderPackets: orderPacket[] = [
  {
    singularTitle: "Plakette aus Acrylglas",
    pluralTitle: "Plaketten aus Acrylglas",
    id: "acrylglas",
    description:
      "Selbstklebende Plakette aus Acrylglas, Weiß bedruckt mit schwarzer Schrift. Maße 21 x 10,5 cm.",
    items: [
      {
        title: "Plakette Ökostrom",
        id: "oekostrom",
        steps: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      },
      {
        title: "Plakette Ökogas",
        id: "oekogas",
        steps: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      },
    ],
  },
  {
    singularTitle: "Tür- und Fensteraufkleber",
    id: "aufkleber",
    description:
      "Selbstklebende Folienschrift. Durchsichtig mit schwarzer Schrift. Maße 34 x 19,6 cm.",
    items: [
      {
        title: "Aufkleber Ökostrom",
        id: "oekostrom",
        steps: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      },
      {
        title: "Aufkleber Ökogas",
        id: "oekogas",
        steps: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      },
    ],
  },
  {
    singularTitle: "Auszeichnung",
    pluralTitle: "Auszeichnungen",
    id: "auszeichnungen",
    image: "auszeichnung.jpg",
    description:
      "Energiewende-Zertifikat in DIN A4 zum Aufhängen. Es zeigt, wie dein Unternehmen mit Polarstern die Energiewende unterstützt: Von der CO2 - Ersparnis, dem weltweiten Ausbau erneuerbarer Energien von Deutschland bis nach Kambodscha und Madagaskar und die Vermeidung radioaktiven Abfalls. Versand ausgedruckt auf weißem Papier (Offset Premium White) oder als PDF.",
    items: [
      {
        title: "Auszeichnung Ökostrom",
        id: "oekostrom",
        steps: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      },
      {
        title: "Auszeichnung Ökogas",
        id: "oekogas",
        steps: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      },
    ],
  },
  {
    singularTitle: "Banner",
    id: "banner",
    description:
      "Banner in verschiedenen Designs und Texten verfügbar. Verlinkung auf die Polarstern-Webseite. Bei Bedarf erstellen wir einen individuellen Text für dein Unternehmen.",
  },
];
</script>
