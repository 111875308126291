//
// This is just a copy from https://github.com/validatorjs/validator.js/blob/master/src/lib/util/assertString.js
// Not nice at all, but the `import { assertString } from 'validator/lib/util/assertString'` does throw errors during
// bundling and we don't have much time to fix it for now.
//

export default function assertString(input: any) {
  const isString = typeof input === "string" || input instanceof String;

  if (!isString) {
    let invalidType: any = typeof input;
    if (input === null) invalidType = "null";
    else if (invalidType === "object") invalidType = input.constructor.name;

    throw new TypeError(`Expected a string but received a ${invalidType}`);
  }
}
