//
// This is just a copy from https://github.com/validatorjs/validator.js/blob/master/src/lib/isBic.js
// Not nice at all, but the `import { isBic } from 'validator/lib/isBic'` does throw errors during
// bundling and we don't have much time to fix it for now.
//

import assertString from "./assertString";
import { CountryCodes } from "./isISO31661Alpha2";

// https://en.wikipedia.org/wiki/ISO_9362
const isBICReg = /^[A-Za-z]{6}[A-Za-z0-9]{2}([A-Za-z0-9]{3})?$/;

export default function isBIC(str: string) {
  assertString(str);

  // toUpperCase() should be removed when a new major version goes out that changes
  // the regex to [A-Z] (per the spec).
  const countryCode = str.slice(4, 6).toUpperCase();

  if (!CountryCodes.has(countryCode) && countryCode !== "XK") {
    return false;
  }

  return isBICReg.test(str);
}
