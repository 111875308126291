<template>
  <FormKit
    v-model="isInterested"
    type="checkbox"
    name="Ich interessiere mich für diese Wanderausstellung"
    label="Ich interessiere mich für diese Wanderausstellung"
    on-value="Ja"
    off-value="0"
  />
  <p>Voraussichtlicher Zeitraum:</p>
  <DateField name="Voraussichtlicher Zeitraum" :required="isInterested !== '0'" />
</template>

<script setup lang="ts">
import DateField from "~/components/01_atoms/customer-area/DateField.vue";

const isInterested = ref("0");
</script>
