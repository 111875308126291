<template>
  <FormKit
    name="bic"
    label="BIC*"
    validation="required|isBicValid"
    :validation-rules="{ isBicValid }"
    :validation-messages="{ isBicValid: 'BIC ungültig' }"
    validation-visibility="blur"
  />
</template>

<script setup lang="ts">
import type { FormKitNode } from "@formkit/core";
import isBic from "~/utils/isBic";

const isBicValid = (node: FormKitNode) => {
  return typeof node.value === "string" ? isBic(node.value) : false;
};
</script>

<style lang="scss"></style>
