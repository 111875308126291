<template>
  <div class="filters--wrapper mt-5 mb-4">
    <div class="filter--wrapper">
      <FormKit
        type="dropdown"
        name="organisationType"
        label="Mitgliedschaft"
        placeholder="Organisation wählen"
        popover
        :options="[
          { value: null, label: 'Organisation wählen' },
          ...organisationTypeOptions.map((option) => ({
            value: option,
            label: option,
          })),
        ]"
        @input="(event) => $emit('organisationType', event || null)"
      />
    </div>

    <div class="filter--wrapper">
      <FormKit
        type="dropdown"
        name="industryType"
        label="Branche"
        placeholder="Branche wählen"
        popover
        :options="[
          { value: null, label: 'Branche wählen' },
          ...industryTypeOptions.map((option) => ({
            value: option,
            label: option,
          })),
        ]"
        @input="(event) => $emit('industryType', event || null)"
      />
    </div>

    <div class="filter--wrapper">
      <FormKit
        v-model="searchString"
        type="text"
        label="Suche"
        placeholder="Namen oder Untertitel suchen"
        @input="emitSearchStringDebounced"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits<{
  (eventName: "organisationType", organisationType: string | null): void;
  (eventName: "industryType", industryType: string | null): void;
  (eventName: "searchString", searchString: string | null): void;
}>();

const searchString = ref<string>("");

const emitSearchStringDebounced = useDebounceFn(() => {
  emit("searchString", searchString.value || null);
}, 300);

const organisationTypeOptions = ["SEND", "Gemeinwohl-Ökonomie", "B Corp", "Impact Hub"];
// TODO: auto generate from json data?
const industryTypeOptions = [
  "Beratung & Agentur",
  "Bildung & Erziehung",
  "Energie",
  "Finanzen",
  "Freizeit & Tourismus",
  "Gastronomie",
  "Gesundheit",
  "Industrie & Produktion",
  "IT & Büro",
  "Kleidung",
  "Kunst & Kultur",
  "Lebensmittel & Landwirtschaft",
  "Services",
  "Sonstige Konsumgüter",
  "Vereine & Organisationen",
  "Wissenschaft & Forschung",
  "Wohnen & Bauen",
  "Medien",
  "Transport",
  "Infrastruktur",
];
</script>

<style lang="scss">
.filters--wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--gap);

  @include breakpoint-from(small) {
    flex-direction: row;
    align-items: flex-end;
  }
}
.filter--wrapper {
  flex: 1 1 0px;

  .input-wrapper {
    margin: 0;
  }
}
</style>
